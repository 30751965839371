import axios from 'axios'

//export const API_BASE_URL = `https://myhiroapi.ngrok.io/api`
// ? `https://api.myhiro.club/api/`
// : `http://localhost:3003/api/`

//export const API_BASE_URL = `https://myhiroapi.ngrok.io/api`

console.log({ API_BASE_URL: process.env.API_BASE_URL, prod: process.env.PRODUCTION })

const api = axios.create({ baseURL: process.env.API_BASE_URL })

export default api
