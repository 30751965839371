import api from '@services/api'
import { User } from '@cnd/common/types'
import { clear } from 'redux-localstorage-simple'
import delay from '@cnd/redash/dist/delay'
import { STRIPE_ENV } from '@services/stripe'
import { ActionParams } from '@store'

const getDevice = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
  userAgent: navigator.userAgent,
  language: navigator.language,
  platform: navigator.platform,
})

const createUser = async (): Promise<Partial<User>> => {
  try {
    const dev = Boolean(process.env.USER_DEV)
    const device = getDevice()
    let newUser: Partial<User> = {
      dev,
      device,
      hideInsights: dev,
    }
    if (dev) console.info({ newUser })
    const { data: user, status } = await api.post(`/createUser`, newUser)
    if (dev) console.info({ newUserCreated: user })
    return user
  } catch (error) {
    console.log('error creating user')
    console.log(error)
    throw {}
  }
}

export const getTempUser = async ({ userId }) => {
  const { data: user } = await api.get(`/tempUsers/${userId}`)
  return user
}

export const asyncUserBootstrapMiddleware = (store) => (next) => (action: ActionParams) => {
  const result = next(action)

  if (action.type === 'SET_PERSISTED_STATE') {
    if (!action.payload.user || action.payload.user.userId === null) {
      console.log('no user found, creating new user')
      store.dispatch({ type: 'SET_AUTH_ID_AND_USER', payload: createUser })
    } else {
      console.log('user found, setting user')
      store.dispatch({
        type: 'SET_AUTH_ID_AND_USER_FULFILLED',
        payload: action.payload.user,
      })
    }
  }

  return result
}

export const login = async (
  accessToken: string,
  userId: string
): Promise<Partial<User & { accessToken: string }>> => {
  const { data: user } = await api.post(`/login`, { userId, accessToken, env: STRIPE_ENV })
  const uesrWithAccesstoken = { ...user, accessToken }
  return uesrWithAccesstoken
}

export const loginAction = ({ accessToken, userId }) =>
  ({
    type: 'LOGIN',
    payload: login(accessToken, userId),
  } as const)

export const logoutAction = (callback?: () => void) =>
  ({
    type: 'LOGOUT',
    payload: new Promise(async (resolve, reject) => {
      try {
        clear({ namespace: 'root' })
        await delay(1000)
        callback()
        resolve(true)
      } catch (error) {
        reject(true)
      }
    }),
  } as const)
