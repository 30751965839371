import styleSheet from '@services/StyleSheet'
import Image from 'next/image'
import { View } from '@cnd/elements'

export const Splash = ({ whatsLoading = null }) => {
  return (
    <View center style={{ flex: 1, ...styleSheet.absoluteFillObject, backgroundColor: 'white' }}>
      {!whatsLoading && <Image src="/images/logo.png" alt="Bountiful Logo" priority width={75} height={75} />}
    </View>
  )
}
