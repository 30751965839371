import { ErrorType, cleanReadableId, uuid } from '@cnd/redash'
import { deliveryPrices } from '@cnd/common/functions/menu'
import { User, Location, ProductOnMenu } from '@cnd/common/types'
import { formatISO } from 'date-fns'

type SidebarBottomContents =
  | ''
  | 'LoginModal'
  | 'LaterDelivery'
  | 'UpdatePhone'
  | 'UpdateEmail'
  | 'AddDeliveryLocation'
  | 'Help'
  | 'PaymentMethodPicker'
  | 'AddPaymentMethod'
  | 'LoginModal'
  | 'Basket'
  | 'ChooseMembership'
  | 'SelectTimeWorked'
  | 'NewUserBasket'
  | 'AddDeliveryLocationForNewUser'
  | 'AddPhoneNumberForNewUser'
  | 'EditNewUserDeliveryDate'
  | 'EditNewUserDeliveryTime'
  | 'Menu'

export type SidebarBottom = {
  isOpen: boolean
  content: SidebarBottomContents
  data: any
  direction: 'left' | 'right' | 'top' | 'bottom'
  animation: 'scale down' | 'push' | 'overlay' | 'uncover' | 'slide out' | 'slide along'
  height: string
}

export type InitialRemoteState = {
  user: Partial<User>
  locations: {
    all: {
      SE16PF: Location & {
        what3words: string
      }
    }
  }
}

type ClosestLocationToUser = {
  isShopOpen: boolean | null
  isShopClosed: boolean | null
  open: string | null
  close: string | null
  launch: number | null
  soldOut: boolean | null
  deliveryOnline: boolean | null
  deliveryAvailable: boolean | null
  offlineMessageSubtitle: string | null
  offlineMessageTitle: string | null
  technicalProblems: boolean | null
  hoursAferNowCanPreorder: number | null
  what3words: string
  openingHours: [
    { id: 'Monday'; openclose: string; order: 1 },
    { id: 'Tuesday'; openclose: string; order: 2 },
    { id: 'Wednesday'; openclose: string; order: 3 },
    { id: 'Thursday'; openclose: string; order: 4 },
    { id: 'Friday'; openclose: string; order: 5 },
    { id: 'Saturday'; openclose: string; order: 6 },
    { id: 'Sunday'; openclose: string; order: 7 }
  ]
} & Location

export type InitialLocalState = {
  menu: {
    items: ProductOnMenu[]
    error: any
    pending: boolean
  }
  onLoginReturnTo: string
  showHeader: boolean
  auth: {
    error: boolean
    pending: boolean
    authStatus: 'requesting-number' | 'requesting-code'
    switch: boolean
    logoutPending: boolean
    logoutError: boolean
  }
  locations: {
    searchExpectedDeliveryTimeFrom: null | string
    searchExpectedDeliveryTimeTo: null | string
    searchPreOrder: boolean
    choosingLocation: boolean
    searchPostcode: string
    searchPostcodeLatLng: { lat: number; lng: number } | null
    pending: boolean
    error?: boolean | null
    closestToUser: null | ClosestLocationToUser
    closeBy: ClosestLocationToUser[]
    postcodeValidError: string
  }
  route: {
    redirectAfterLogin: null | string
    redirectAfterSignUp: null | string
    previous: string | null
    current: null | string
    redirectAfterOrderPaymentComplete: null | string
    onRouteChangeCache: null | any
  }
  appVersion: { current: string | null }
  sidebar: boolean
  sidebarBottom: SidebarBottom
  user: {
    loadingFailed: boolean | null
    defaultDeliveryAddress: {
      pending: boolean
      error: boolean
    }
    application?: {
      pending: boolean
      error: boolean
    }
    paymentMethodsPending: boolean
    paymentMethodsError: any
    paymentMethodsRejected: boolean
  }
}

export type DeliveryLocationKeys = keyof User['defaultDeliveryAddress']

export type InitialState = InitialRemoteState & InitialLocalState

export type RouteState = InitialState['route']
export type LocationsState = InitialState['locations']
export type UserState = InitialState['user']
export type UserOrderState = InitialState['user']['order']
export type OnLoginReturnToState = InitialState['onLoginReturnTo']
export type AuthState = InitialState['auth']
export type SidebarState = InitialState['sidebar']
export type SidebarBottomState = InitialState['sidebarBottom']
export type ShowHeaderState = InitialState['showHeader']
export type AppVersionState = InitialState['appVersion']
export type BusinessHoursState = InitialState['locations']['closestToUser']

const initialState: InitialState = {
  route: {
    redirectAfterOrderPaymentComplete: null,
    redirectAfterLogin: null,
    redirectAfterSignUp: null,
    previous: null,
    current: null,
    onRouteChangeCache: null,
  },
  menu: {
    items: [],
    pending: true,
    error: null,
  },
  locations: {
    searchExpectedDeliveryTimeFrom: null,
    searchExpectedDeliveryTimeTo: null,
    searchPostcodeLatLng: null,
    searchPreOrder: false,
    choosingLocation: false,
    postcodeValidError: '',
    searchPostcode: '',
    all: {
      SE16PF: {
        name: 'Elephant & Castle',
        streetAddress1: '50 Rockingham Street',
        latLng: { lat: 51.4956054, lng: -0.09707 },
        what3words: '///brings.pilots.turkey',
        city: 'London',
        postcode: 'SE1 6PF',
        type: 'dark',
        url: '50+Rockingham+St,+Elephant+and+Castle,+London+SE1+6PF',
        minsFromLocation: null,
      },
    },
    closeBy: [],
    pending: false,
    error: null,
    closestToUser: null,
  },
  user: {
    loadingFailed: null,
    relics: {},
    accountReference: '',
    orderCount: 0,
    totalPeopleReferred: 0,
    sharingCode: '',
    totalSpent: 0,
    loaded: false,
    loading: false,
    loadingError: null,
    isMember: false,
    isLoggedIn: false,
    smilies: 0,
    membership: {
      freeOrderProbability: 1,
      shortname: 'hiro',
      confirmed: true,
      selected: true,
    },
    orders: {},
    selectedPaymentMethod: null,
    paymentMethods: null,
    paymentMethodsPending: false,
    paymentMethodsError: null,
    paymentMethodsRejected: false,
    defaultDeliveryAddress: {
      streetAddress1: '',
      streetAddress2: '',
      city: 'London',
      postcode: '',
      notes: '',
      saved: false,
      pending: false,
      error: false,
    },
    stripeDevCustomerId: null,
    stripeLiveCustomerId: null,
    device: {},
    ipAddress: '',
    createdAt: '',
    referralCode: '',
    pending: false,
    error: null,
    dev: null,
    hideInsights: false,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    tempPhoneNumber: '',
    email: '',
    dateOfBirthDay: '',
    dateOfBirthMonth: '',
    dateOfBirthYear: '',
    billingAddress: {
      addressLineOne: '',
      addressLineTwo: '',
      city: '',
      postcode: '',
      label: '',
      driverNotes: '',
    },
    accessToken: '',
    userId: null,
    application: {
      pending: false,
      error: null,
      homePostcode: '',
      commuteTime: null,
      commuteTimeInRange: null,
      personalityQuestions: null,
      personalityAnswers: null,
      personalityResults: null,
      problemQuestions: null,
      problemAnswers: null,
      problemResults: null,
      jobDescription: null,
      acceptedTerms: false,
    },
  },
  onLoginReturnTo: '/',
  showHeader: true,
  auth: {
    logoutPending: true,
    logoutError: false,
    error: false,
    pending: false,
    authStatus: 'requesting-number',
    switch: false,
  },
  sidebar: false,
  sidebarBottom: {
    animation: 'overlay',
    direction: 'bottom',
    isOpen: false,
    content: null,
    data: {},
    height: 'auto',
  },
  appVersion: { current: null },
}

export default initialState
