import { legacy_createStore as createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import promiseMiddleware from 'redux-promise-middleware'

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { asyncUserBootstrapMiddleware } from './userMiddleware'
import { clear, save } from 'redux-localstorage-simple'
import { mapObjIndexed, mergeDeepRight } from 'ramda'
import { createReducer } from '@cnd/redash/dist/funstore'
import initialConfiguration, { ACTION_NAMES, ActionPayload } from './initialConfiguration'
import initialState, { InitialState } from './initialState'
import { isWebsite } from '@services/platform'
import { Equals } from '@cnd/redash/dist/safeTry/safeTry'
import { firebaseListenerMiddleware } from './useFirebaseConnect'

export const useTypedSelector: TypedUseSelectorHook<InitialState> = useSelector

export type ActionParams = {
  type: ACTION_NAMES
  payload?: any
}
type Dispatch = <TReturnType>(action: ActionParams) => TReturnType
export const useTypedDispatch = () => useDispatch<Dispatch>()

export const useFunDispatch = () => {
  const dispatch = useDispatch<Dispatch>()

  function dispatcher<ActionName extends ACTION_NAMES, T = ActionPayload<ActionName>>(
    type: ActionName
  ): (payload?: T) => any
  function dispatcher<ActionName extends ACTION_NAMES>(
    type: ActionName,
    payload?: ActionPayload<ActionName>
  ): any
  function dispatcher<ActionName extends ACTION_NAMES, T = ActionPayload<ActionName>>(
    type: ActionName,
    payload?: T
  ): Equals<T, undefined> extends true ? (payload?: T) => any : any {
    if (arguments.length === 1) return (payload?: ActionPayload<ActionName>) => dispatch({ type, payload })
    return dispatch({ type, payload })
  }

  return dispatcher
}

const middleware = [thunk, promiseMiddleware, asyncUserBootstrapMiddleware, firebaseListenerMiddleware([])]

if (isWebsite) {
  const logger = createLogger({ diff: true })
  middleware.push(logger)
}

middleware.push(save({ namespace: 'root' }))

const appReducer = combineReducers(mapObjIndexed(createReducer, initialConfiguration))

const rootReducer = (state: InitialState, action) => {
  if (action.type === 'CLEAR_STATE') {
    clear({ namespace: 'root' })
    return appReducer(initialState, action)
  }

  if (action.type === 'SET_PERSISTED_STATE') {
    return appReducer(mergeDeepRight(initialState, action.payload), action)
  }

  return appReducer(state, action)
}

export default createStore(rootReducer, initialState, compose(applyMiddleware(...middleware)))
